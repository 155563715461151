<script>

	import { onMount, getContext } from 'svelte';
	import { fade } from "svelte/transition";

	import { event, room, session } from "../../../lib/stores.js";

	const overlay = getContext('overlay');
	const overlays = getContext('overlays');

	let image = null;
	let fadeDuration = 0;

	onMount(() => {
		let img = null;
		if ($overlay) {
			if ($event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.overlays) {
				for (const b of $event.setup.virtual.studio.overlays) {
					if (b.content.ref == $overlay) {
						img = b.content;
						break;
					}
				}
			}
			if (!img) {
				for (const o of $overlays) {
					if (o.ref == $overlay) {
						img = o;
						break;
					}
				}
			}
		}
		image = img;
	});

	$: fadeDuration = ($room && $session) ? 200 : 0;

</script>

<style>
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		object-fit: cover;
		pointer-events: none;
	}
</style>

{#if image}
	{#if image.format == 'svg'}
		<img
			class="overlay"
			src="https://cdn.attendzen.io/{$event.accountRef}/{image.filename}"
			alt=""
			transition:fade={{ duration: fadeDuration }}
		/>
	{:else}
		<img
			class="overlay"
			src="https://cdn.attendzen.io/{$event.accountRef}/max_{image.filename}"
			alt=""
			transition:fade={{ duration: fadeDuration }}
		/>
	{/if}
{/if}