<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from "svelte/transition";

	import moment from 'moment-timezone';
	import Portal from "svelte-portal";

	import Session from "./Deep/Session.svelte";
	import Menu from "../ui/Menu.svelte";

	import { event, agenda, agendaDay, agendaTrack, displayInEventTz, epoch } from '../lib/stores.js';
	import { fromUTC, toUTC, showDate, iso, isToday } from '../lib/dt.js';

	const deep = getContext('deep');

	const tz = $event.setup.timeZone;

	let days = enumerateDaysBetweenDates($event.starts, $event.ends);

	let sessions;
	let sorted;

	let trackName = '';
	let trackMenu = false;

	let mounted = false;
	let timelineKey;

	let selectedSession = null;

	function setAgenda(m) {
		if (mounted || m) {
			sorted = $agenda.sessions.sort(function(a,b){
				return moment(a.starts).diff(moment(b.starts));
			});
			setSessions(true);
		}
	}

	onMount(() => {
		if ($agenda) setAgenda(true);
		mounted = true;
	});

	if (!$agendaDay) {
		$agendaDay = days[0];
		days.forEach((d) => {
			if (isToday(moment.utc(d))) {
				$agendaDay = d;
			}
		});
		// console.log('days', days);
		// console.log('agendaDay', $agendaDay);
	}

	if ($agenda && !$agendaTrack) $agendaTrack = $agenda.tracks[0].ref;

	function enumerateDaysBetweenDates(startDate, endDate) {
		let dates = [];
		// console.log('dates', startDate, endDate);
		// let es = fromUTC(startDate,tz).set({'hour': 0, 'minute': 0}).utc();
		// let ee = fromUTC(endDate,tz).set({'hour': 0, 'minute': 0}).utc();
		let es = moment.utc(startDate);
		let ee = moment.utc(endDate);
		let now = es;
		while (now.isSameOrBefore(ee)) {
			dates.push(now.toISOString());
			now.add(1, 'days');
		}
		return dates;
	}

	function setSessions(m) {
		if (mounted || m) {
			sessions = [];
			if (sorted) {
				sorted.forEach(s => {
					// console.log('setSessions', s.starts, s.track, $agendaTrack, $agendaDay);
					if ((s.starts) && (!s.track || (s.track == $agendaTrack))) {
						// s.starts = fromUTC(s.starts,tz);
						if (s.starts.isSame($agendaDay,"day")) {
							// s.ends = fromUTC(s.ends,tz);
							// s.epochStarts = s.starts.utc().unix();
							// s.epochEnds = s.starts.utc().unix();
							s.speakers = [];
							if (s.details) {
								for (const st of $agenda.sessionTypes) {
									if (s.sessionType == st.ref) {
										for (const c of st.components) {
											if (((c.component == 'Moderators') && (s.details[c.ref])) || ((c.component == 'Speakers') && (s.details[c.ref]))) {
												for (const d of s.details[c.ref]) {
													if (d.crm) {
														s.speakers.push({
															...d.crm,
															component: c.component
														});
													}
												}
											}
										}
										break;
									}
								}
							}
							sessions.push(s);
						}
					}
				});
			}
			sessions = sessions;
		}
	}

	function setTrackName() {
		trackName = '';
		for (const t of $agenda.tracks) {
			if (t.ref == $agendaTrack) {
				trackName = t.name;
				break;
			}
		}
	}

	function setTrack(e) {
		$agendaTrack = e.detail.ref;
		trackMenu = false;
	}

	function setTimelineKey() {
		timelineKey = $agendaDay + $agendaTrack;
	}

	function selectSession(s) {
		selectedSession = s;
		$deep = true;
	}

	$: if ($agenda) {
		setAgenda();
	}

	$: if ($agendaDay || $agendaTrack) {
		setSessions();
		setTimelineKey();
	}

	$: if ($agendaTrack) {
		setTrackName();
	}

</script>

<style>
	.agenda {
		position: absolute;
		inset: 0;
		overflow: auto;
		scrollbar-width: thin;
		padding-bottom: 0.5rem;
	}
	.utils {
		position: sticky;
		top: 0;
		margin: 0 0.8rem;
		padding: 0.25rem 0 0.3rem 0;
		border-bottom: 1px solid var(--blend-80, #888);
		/*background: var(--blend-10, #ddd);*/
		background: var(--panelColor);
		font-size: 0.625rem;;
		z-index: 100;
		box-sizing: border-box;
	}
	.dates ul {
		margin: 0;
		padding: 0;
		display: flex;
	}
	.dates li {
		display: block;
		margin: 0;
		padding: 0.2rem 0.4rem;
		border: 1px solid var(--accentColor);
		border-radius: 4px;
		line-height: 1;

	}
	.dates li + li {
		margin-left: 0.5rem;
	}
	.dates li:hover {
		cursor: pointer;
		/*color: var(--accentColor, #e6007e);*/
		color: var(--accentBlend-120);
	}
	.dates li.current {
		font-weight: 600;
		color: var(--panelColor);
		background: var(--accentColor);
	}
	.dates + .tracks {
		margin-top: 0.5rem;
	}
	.tracks {
		display: flex;
		justify-content: space-between;
	}
	.tracks span {
		position: relative;
		display: block;
		margin: 0;
		padding: 0.25rem 1.2em 0.2rem 0;
		font-weight: 600;
		font-size: 0.6875rem;
	}
	.tracks svg {
		display: inline-block;
		width: 0.5em;
		transform: rotate(90deg);
		position: relative;
		left: -0.25rem;
		transition: transform 0.2s ease;
		height: 1rem;
	}
	.tracks.open svg {
		transform: rotate(-90deg);
	}
	.tracks:hover {
		cursor: pointer;
		color: var(--accentColor, #e6007e);
	}

	.timeline {
		padding: 0 0.8rem;
	}

	.timeline > div {
		position: relative;
		padding: 0.5rem 0.4rem 0.5rem 2.6rem;
		font-size: 0.6875rem;;
		line-height: 1.4;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		cursor: pointer;
	}

	.timeline > div:hover {
		background: var(--blend-05);
	}

	.timeline > div + div {
		margin-top: 0.5rem;
		border-top: 1px solid var(--blend-40, #888);
	}

	.now {
		background: var(--panelColor, #fff);
	}

	.time {
		font-size: 0.625rem;;
		position: absolute;
		top: 0.95rem;
		left: 0.4rem;
		color: var(--blend-80, #555);
		line-height: 0;
	}

	.session {
		font-weight: 700;
	}

	.speaker {
		position: relative;
		min-height: 1.5rem;
		/*padding-left: 2rem;*/
		font-size: 0.625rem;
		margin-top: 0.5rem;
	}

	.speaker em {
		display: inline-block;
		margin-left: 0.1rem;
		color: var(--accentColor);
		font-style: normal;
		font-weight: 600;
		font-size: 0.625rem;;
	}

	/*.avatar {
		position: absolute;
		top: 0;
		left: 0;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 100%;
	}*/

	.live {
		display: inline-block;
		background: var(--red, #d73531);
		color: var(--redText, #fff);
		text-transform: uppercase;
		font-size: 0.5rem;
		border-radius: 2px;
		line-height: 1;
		padding: 2px 3px;
		letter-spacing: 0.05em;
		margin-right: 0.5em;
		position: relative;
		top: -0.075rem;
	}

	.tracks :global(.menu) {
		position: absolute;
		top: calc(100% - 2px);
		left: 0;
		width: 100%;
	}

</style>

{#if mounted}
	<div class="agenda" in:fly={{ duration: 300, y: 20 }}>
		{#key $displayInEventTz}
			<div>

				{#if (days.length > 1) || ($agenda && ($agenda.tracks.length > 1))}
					<div class="utils">
						{#if days.length > 1}
							<div class="dates">
								<ul>
									{#each days as d}
										<!-- TODO: nest a button instead -->
										<!-- svelte-ignore a11y-click-events-have-key-events -->
										<li
											class:current={d == $agendaDay}
											on:click={() => {	$agendaDay = d }}
										>
											{showDate(d, 'ddd DD')}
										</li>
									{/each}
								</ul>
							</div>
						{/if}
						{#if $agenda && $agenda.tracks && $agenda.tracks.length > 1}
							<!-- TODO: nest a button instead -->
							<!-- svelte-ignore a11y-click-events-have-key-events -->
							<div
								class="tracks"
								class:open={trackMenu}
								on:click|stopPropagation={() => { trackMenu = !trackMenu }}
							>
								<span>{trackName}</span>
								<svg viewBox="0 0 16.7 29.5"><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"/></svg>
								{#if trackMenu}
									<Menu
										options={$agenda.tracks}
										on:selected={setTrack}
										on:escape={() => { trackMenu = false }}
										arrow={false}
										tick={$agendaTrack}
									/>
								{/if}
							</div>
						{/if}
					</div>
				{/if}

				{#if $agenda}
					{#key timelineKey}
						<div class="timeline" in:fly={{ duration: 300, y: 20 }}>

							{#each sessions as s}
								<!-- TODO: nest a button instead -->
								<!-- svelte-ignore a11y-click-events-have-key-events -->
								<div
									class:now={(s.virtual && s.virtual.isVirtual) && ($epoch > s.epochStarts) && ($epoch < s.epochEnds)}
									on:click={selectSession(s)}
								>
									<p class="time">{showDate(s.starts, 'HH:mm')}</p>
									<p class="session">
										{#if (s.virtual && s.virtual.isVirtual) && ($epoch > s.epochStarts) && ($epoch < s.epochEnds)}<span class="live">Now</span>{/if}
										{s.name}
									</p>
									{#each s.speakers as speaker}
										<p class="speaker">
											<strong>{speaker.n}</strong>
											{#if speaker.component == 'Moderators'}<em>Moderator</em>{/if}
											{#if speaker.o}<br/><span>{speaker.o}</span>{/if}
										</p>
									{/each}
								</div>
							{/each}

						</div>
					{/key}
				{/if}

			</div>
		{/key}
	</div>
{/if}

{#if selectedSession}
	<Portal target="#deepInfo">
		<Session {selectedSession} on:back={() => { selectedSession = null }}/>
	</Portal>
{/if}