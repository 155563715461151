<script>

	import cssVars from 'svelte-css-vars';

	import { event } from '../lib/stores.js';

	export let card;

	let vars = {
		"cardBgColor": $event.theme.colors[card.bgColor],
		"focalPoint": card.image.focalPointX + '% ' + card.image.focalPointY + '%'
	};

	if (card.imgTintColor) {
		vars.tintColor = $event.theme.colors[card.imgTintColor];
	}

	let url = "https://cdn.attendzen.io/" + $event.accountRef + '/max_' + card.image.filename;

	if (card.image.unsplash) {
		url = card.image.unsplash.url + '&w=1200';
	}

	if (card.imagePresentation == 'tinted') {
		let maskURL;
		if (card.image.format == 'svg') {
			maskURL = "https://cdn.attendzen.io/" + $event.accountRef + '/mask_' + card.image.filename.replace(/\.svg$/, '.png');
		} else {
			maskURL = "https://cdn.attendzen.io/" + $event.accountRef + '/mask_' + card.image.filename.replace(/\.jpe?g$/, '.png');
		}
		url = maskURL;
		vars.mask = 'url("' + maskURL + '")';
	}

</script>

<style>

	.img {
		position: absolute;
		inset: 0;
		background: var(--cardBgColor, transparent);
  		border-radius: 4px;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: var(--focalPoint, 50% 50%);
  		border-radius: 4px;
	}

	.logo {
		position: absolute;
		inset: 3rem;
	}

	.logo img {
		object-fit: contain;
		object-position: 50% 50%;
  		border-radius: unset;
	}

	@supports (-webkit-mask-image: url("")) or (mask-image: url("")) {
		.img.tinted div.logo:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			-webkit-mask-image: var(--mask);
			-webkit-mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 50% 50%;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: 50% 50%;
			background: var(--tintColor);
		}
		.img.tinted .logo img {
			visibility: hidden;
		}
	}

</style>

<div class="img" class:tinted={card.imagePresentation == 'tinted'} use:cssVars={vars}>
	<div class:logo={card.image.type == 'logo'}>
		<img src={url} alt={card.alt}>
	</div>
</div>